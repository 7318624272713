(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/nav-context/assets/javascripts/nav-context-provider.js') >= 0) return;  svs.modules.push('/modules/oddset/components/nav-context/assets/javascripts/nav-context-provider.js');
"use strict";


const {
  useLocation,
  matchPath
} = ReactRouterDOM;
const {
  useState
} = React;
const {
  NavContext
} = svs.oddset.components.nav_context;
const getCurrentRoute = (routes, activePathname) => routes.find(route => matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const NavContextProvider = _ref => {
  let {
    children
  } = _ref;
  const routes = svs.oddset.data.tabsConfig;
  const playRoute = svs.oddset.data.tabsConfigPlay;
  const defaultLocation = useLocation();
  const [activePathname, setActivePathname] = useState(defaultLocation.pathname);
  return React.createElement(NavContext.Provider, {
    value: {
      activePathname,
      setActivePathname,
      currentRoute: getCurrentRoute([...routes, playRoute], activePathname)
    }
  }, children);
};
setGlobal('svs.oddset.components.nav_context.NavContextProvider', NavContextProvider);

 })(window);