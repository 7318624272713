(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/nav-context/assets/javascripts/nav-context.js') >= 0) return;  svs.modules.push('/modules/oddset/components/nav-context/assets/javascripts/nav-context.js');
"use strict";


const {
  createContext
} = React;
const NavContext = createContext();
setGlobal('svs.oddset.components.nav_context.NavContext', NavContext);

 })(window);